import head from './img/head.jpg';
import './App.css';
import Button from './components/Button'

function App() {
  return (
    <div className="wrapper">
      <header className="App-header">
        <img src={head} className="head-image" alt="logo" />       
      </header>
      <div className='button-wrapper'>
        <Button href="https://www.digitavolo.com/panoramico/menu" label="Ristorante Panoramico"/>
        <Button href="https://www.digitavolo.com/barhall/menu" label="Bar Hall"/>
        <Button href="https://www.digitavolo.com/ficodindia/menu" label="Fico d'india / Bar Piscina"/>
        <Button href="https://www.digitavolo.com/lymphaspa/menu" label="Limpha wellness & SPA"/>
      </div>
      <div className="footer">© Copyright 2022   |  Costa Brada Srl</div>
    </div>
  );
}

export default App;
