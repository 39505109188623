import styles from "./Button.module.css";
export default function Button({ href, label }) {
    return (
        <div className={styles.wrapper}>
            <a href={href}>
                <button className={styles.button}>{label}</button>
            </a>
        </div>
    );
}
